import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { formatter } from '../utils/currencyUtils';
import { ImSpinner } from "react-icons/im";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const AddonPayments = () => {
  useKYCStatusRedirect("/addon-payments");
  const { school } = useStateContext();
  const [classes, setClasses] = useState();
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [loading, setLoading] = useState(false);
  //console.log(accessCode);
  //console.log(schoolCode);

  useEffect(() => {
    if (schoolId) {
      fetchClassAddon();
    }
  }, [schoolCode, schoolId]);

  /*const fetchData = async () => {
    setLoading(true);
    try {
      const [classesResponse, paymentSummaryResponse] = await Promise.all([
        userRequest.get(`/class-addon/${schoolId}`),
        userRequest.get(`schools/${schoolId}/payment-summary?term=${school.term}&session=${school.session}`),
      ]);
      console.log(classesResponse.data);
      const addOnsWithClassName = classesResponse.data.flatMap(classItem =>
        classItem.add_on.map(addOn => ({
          ...addOn,
          class_name: classItem.class_name
        }))
      );
      setClasses(addOnsWithClassName);
      setPaymentSummary(paymentSummaryResponse.data);
      console.log(paymentSummaryResponse.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };*/

  const fetchClassAddon = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/class-addon/${schoolId}`);

      console.log(data);
      const addOnsWithClassName = data.flatMap(classItem =>
        classItem.add_on.map(addOn => ({
          ...addOn,
          class_name: classItem.class_name
        }))
      );
      setClasses(addOnsWithClassName);
      setLoading(false);
    } catch (error) {
        console.log(error);
    }
  };

  const columns = [
    {
      name: "Addon Name",
      selector: row => row?.add_on_name
    },
    {
      name: "Class Name",
      selector: row => row?.class_name
    },
    {
      name: "School Fees",
      selector: row => <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{formatter.format(row?.add_on_price)}</button>
    },
    {
      name: "Options",
      selector: (row) => <Link to={`/addon-payments/students/${row?._id}/${row?.add_on_name}`}><button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">View Details <MdKeyboardArrowRight /></button></Link>,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <Header category="Payments" title="Payment Per Addon" />
      {/*<PaymentSummary paymentSummary={paymentSummary} />*/}
      <DataTable
        data={classes}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />
    </div>
  );
};
export default AddonPayments;
