import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import { signinSchema } from '../../utils/formSchema';
import { publicRequest } from '../../utils/requestMethods';
import Cookies from 'js-cookie';
import './css/default.css'
import './css/style.css'
import { ButtonLoading } from '../../components';
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import flower from './img/flower.png';
import man1 from './img/man-1.png';
import man2 from './img/man-2.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';

const SignIn = ({ history }) => {
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);

  const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
   } = useFormik({
      initialValues: {
         email: "",
         password: "",
      },
      validationSchema: signinSchema,
      onSubmit: (values) => {
         // Handle form submission here
         loginHandler(values);
         //console.log(values);
      },
   });

   useEffect(() => {
      if (Cookies.get('accessToken')) {
         history.push("/");
      }
   }, [history]);

   const loginHandler = async (values) => {
      setLoading(true);
      
      try {
         const { data } = await publicRequest.post("auth/login", values);

         const expireTime = new Date(new Date().getTime() + 5 * 60 * 60 * 1000);
         Cookies.set('accessToken', data.access_token, { secure: true, sameSite: 'strict', expires: expireTime });

         const updatedData = { ...data };
         delete updatedData.access_token;
         localStorage.setItem("tokenWithUser", JSON.stringify(updatedData));
         
         /*if (updatedData.schools?.schools[0].kyc_progress === "pending") {
            //history.push("/onboarding/status");
            window.location.href = "/onboarding/status";
         } else if (updatedData.schools?.schools[0].kyc_status === "not_started") {
            //history.push("/onboarding/kyc");
            window.location.href = "/onboarding/kyc";
         } else if (updatedData.schools?.schools[0].kyc_status === "rejected") {
            //history.push("/onboarding/kyc");
            window.location.href = "/onboarding/rejected";
         } else {
            //history.push("/");
            window.location.href = "/";
         }*/
         window.location.href = "/";
         //console.log({finalData});
      } catch (error) {
         console.log(error);
         setError("Wrong email address or password!");
         setTimeout(() => {
            setError("");
         }, 5000);
         setLoading(false);
      }
   };

    return (
      <main>
          <section className="signup__area po-rel-z1 p-t-40 pb-100">
            <div className="sign__shape">
               <img className="man-1" src={man1} alt="" />
               <img className="man-2" src={man2} alt="" />
               <img className="circle" src={circle} alt="" />
               <img className="zigzag" src={zigzag} alt="" />
               <img className="dot" src={dot} alt="" />
               <img className="bg" src={signup} alt="" />
               <img className="flower" src={flower} alt="" />
            </div>
            <div className="row">
               <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                  <div className="section__title-wrapper text-center m-b-40">
                     <img src={logo} alt="logo" className="mx-auto" width="250" />
                  </div>
               </div>
            </div>
            <div className="max-w-xl mx-auto mt-3 divide-y p-3">
               {error &&
                  <div className="alert alert-warning d-flex align-items-center" role="alert">
                     <i className="fal fa-lock pr-10"></i>
                     <div>
                        <b>Error! </b>{error}
                     </div>
                  </div>
               }
                <div className="sign__wrapper bg-white mx-auto">
                  <div className="sign__header mb-35">
                     <div className="sign__in text-center">
                        <h3 className="text-2xl font-bold section__title">Sign into your Account.</h3>
                     </div>
                  </div>
                  <div className="sign__form px-2">
                     <form onSubmit={handleSubmit}>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Work email</h5>
                           <div className="sign__input">
                              <input type="email" id="email" placeholder="Email address" value={values.email} onBlur={handleBlur} onChange={handleChange} required/>
                              <i className="fal fa-envelope"></i>
                              {errors.email && touched.email &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.email}</p>}
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-6">
                           <h5>Password</h5>
                           <div className="sign__input">
                              <input type="password" id="password" placeholder="Password" value={values.password} onBlur={handleBlur} onChange={handleChange} required/>
                              <i className="fal fa-lock"></i>
                              {errors.password && touched.password &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.password}</p>}
                           </div>
                        </div>
                        <div className="sign__action d-sm-flex justify-content-between mb-30">
                           <div className="sign__agree d-flex align-items-center pr-2">
                              <input className="m-check-input" type="checkbox" id="m-agree" />
                              <label className="m-check-label" htmlFor="m-agree">Keep me signed in
                                 </label>
                           </div>
                           <div className="sign__forgot">
                              <Link to="/auth/reset">Forgot password?</Link>
                           </div>
                        </div>
                        {loading ?
                           (
                              <ButtonLoading styles="e-btn w-100 auth-btn" />
                           ) : (
                              <button type="submit" className="e-btn w-100 auth-btn"> <span></span> Sign In</button>
                           )
                        }
                        <div className="sign__new text-center mt-10">
                           <p>New to Acceede? <Link to="/signup">Sign Up</Link></p>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </main> 
    );
};
export default SignIn;
