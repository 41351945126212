/* Create our Currency formatter.
export const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0,
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as ₦2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as ₦2,501)
  });*/


export const customFormatter = (valueInKobo) => {
  // Check if the value is empty, null, or undefined
  if (valueInKobo === null || valueInKobo === undefined || valueInKobo === '') {
    return '₦ 0.0'; // Return '₦0' for empty, null, or undefined values
  }

  // Convert Kobo to Naira (divide by 100)
  const valueInNaira = valueInKobo / 100;

  // Use the Intl.NumberFormat to format the value in Naira
  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });

  return formatter.format(valueInNaira); // Format the value in Naira using the formatter
};

export const getPriceForTerm = (classPrice, term) => {
  return classPrice[term] || "0.0";
};

  // Create our Currency formatter.
export const formatter = new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as ₦2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as ₦2,501)
});

//(2500).toLocaleString("en-NG", {style: "currency", currency: "NGN", minimumFractionDigits: 2})