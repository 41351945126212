import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { userRequest, formatter } from '../utils/requestMethods';
import { Header, AddFeeModal } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Addons = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  const className = location.pathname.split('/')[3];
  const { school } = useStateContext();
  const [addons, setAddons] = useState();
  const [addonName, setAddonName] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const schoolId = school?._id;
  //const schoolCode = school?.code;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(addonName, addonPrice, error);

  useEffect(() => {
    const fetchAddons = async () => {
      if (schoolId) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`class-addon/${schoolId}/${classId}`);
          console.log(data.add_on);
          setAddons(data.add_on);
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAddons();
  }, [classId, schoolId, refresh]);

  const goBack = () => {
    // Go back to the previous page
    history.goBack();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!addonName || !addonPrice) {
        setError("Please Fill all the Feilds");
        setTimeout(() => {
            setError("");
        }, 5000);
        setLoading(false);
        return;
    }

    try {
        await userRequest.post(`/class-addon/${schoolId}/${classId}`,
            {
              add_on_name: addonName,
              add_on_price: addonPrice,
            });

        //console.log(data);
        //setAddons(data.add_on);
        setRefresh(!refresh);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setAddonName("");
            setAddonPrice("");
            toggleModal();
        }, 2000);
    } catch (error) {
        console.log(error);
        setError(error.response.data);
        setTimeout(() => {
            setError("");
        }, 5000);
        setLoading(false);
    } finally {
      setLoading(false);
    }
    
};

  const deleteHandler = async (addonId) => {
    //console.log(classId);
    try {
      setLoading(true);
      await userRequest.delete(`/class-addon/${schoolId}/${classId}/${addonId}`);
      //console.log(res.data.add_on);
      //setAddons(data.add_on);
      setRefresh(!refresh);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "email",
      name: "Extra Fee",
      selector: row => <span className="font-bold">{row?.add_on_name}</span>
    },
    {
      field: "price",
      name: "Amount",
      selector: row => formatter.format(row?.add_on_price)
    },
    {
      cell: (row) => <button style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">Active</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <Link to={`/update-addon/${classId}/${row?._id}`} style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiEdit /></Link>,
      ignoreRowClick: true,
      button: true,
    },
    {
      cell: (row) => <button onClick={() => deleteHandler(row?._id)} style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiTrash2 /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
      <button type="button" onClick={toggleModal} style={{ backgroundColor: 'blue' }} className="inline-flex justify-center py-2 px-4 mx-2 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
        Add Extra Fees
      </button>
      <Header category="Set Up" title={className + " Extra Fees"} />
      
      <DataTable
        data={addons}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />

      {isModalOpen && 
        <AddFeeModal 
        closeModal={toggleModal} 
        addonName={addonName}
        setAddonName={setAddonName}
        addonPrice={addonPrice}
        setAddonPrice={setAddonPrice}
        error={error}
        success={success}
        loading={loading} 
        submitHandler={submitHandler}
        />
      }
    </div>
  );
};
export default Addons;
