import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { userRequest, formatter } from '../utils/requestMethods';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";
import { displaySchoolTerm } from '../utils/schoolYearUtils';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
  };

const TransactionDetails = ({ history }) => {
    const { school } = useStateContext();
    const schoolId = school?._id
    const location = useLocation();
    const loanReference = location.pathname.split('/')[3];
    console.log(loanReference);
    const [transaction, setTransaction] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

    useEffect(() => {
        const fetchClasses = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`/loans/school/${schoolId}/${loanReference}`);
            //console.log(data);
            setTransaction(data);
            //setTransactions(data.repayments)
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchClasses();
    }, [schoolId, loanReference]);

    const statusHandler = async (statusValue) => {
        //e.preventDefault();
        setLoading(true);
        //console.log(statusValue);
        //console.log(transaction.choose_wards);
        try {
          const { data } = await userRequest.put(`/loans/school/${transaction.loanReference}`,{
                //approved_wards: transaction.choose_wards,
                status: statusValue,
                //isApproved: true,
                //credit_decision: statusValue
            }
          );
          console.log(data);
          if (statusValue === "full") {
            setSuccess("Application Status has been set to Accepted!");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
          } else if (statusValue === "reject") {
            setError("Application Status has been set to " + statusValue + "!");
            setTimeout(() => {
              setError("");
            }, 3000);
          }
        } catch (error) {
            console.log(error);
            if (error.response.data) {
                setError("Error! " + error.response.data);
                setTimeout(() => {
                    setError("");
                }, 2000);
            } else {
                setError("Something went wrong, try again later!");
                setTimeout(() => {
                    setError("");
                }, 2000);
            }
        }
        setLoading(false);
    };

    const columns = [
        {
          name: "Reference",
          selector: row => row?.reference
        },
        {
          name: "Amount",
          selector: row => <b>{formatter.format(row?.amount	)}</b>
        },
        {
          name: "Status	",
          selector: (row) => <button style={row?.status	 === "successful" ? { background: 'rgb(139, 231, 139)' } : row?.status === "failed" ? { background: 'rgb(227, 79, 79)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row?.status === "successful" ? ("Successful") : row?.status === "failed" ? ("Failed") : ("Pending")}</button>
        },
        /*{
          name: "Narration",
          selector: row => row?.narration
        },*/
        {
          name: "Date",
          selector: row => (new Date(row?.transaction_date)).toLocaleString('en-NG', {hour12: true}),
          sortable: true
        },
      ];
    
  return (
    <div className="m-4 md:m-10 mt-24 p-4 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        {success && (
            <div className="alert alert-success flex items-center" role="alert">
                <i className="fal fa-check pr-2 font-bold"></i>
                <div>{success}</div>
            </div>
        )}
        {error && (
            <div className="alert alert-warning flex items-center" role="alert">
                <i className="fal fa-lock pr-2 font-bold"></i>
                <div>{error}</div>
            </div>
        )}
        <div className="flex flex-wrap justify-between mb-4">
            <button onClick={goBack} className="flex items-center justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <i className="fad fa-arrow-left"></i>
            </button>
            <div className="flex space-x-2">
                <button type="button" onClick={() => statusHandler("reject")} disabled={loading} className="flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2">
                    Reject
                </button>
                <button type="button" onClick={() => statusHandler("full")} disabled={loading} className="flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
                    Approve
                </button>
            </div>
        </div>
        <Header category="Flexpay" title={transaction?.user_details?.fullName} />
        {loading ? (
            <div className="py-4 flex justify-center items-center h-full">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <div className="grid grid-cols-1 gap-6">
                        <p className="block text-center">
                            <ImSpinner className="animate-spin text-4xl text-gray-400" />
                        </p>
                    </div>
                </div>
            </div>
        ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mb-2 m-2 mt-10">
            <div className="bg-white border dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
                <div>
                    <p className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Flexpay Details
                    </p>
                </div>
                <div className="mt-4">
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Flexpay ID</p>
                                <p className="text-md font-semibold">{transaction?._id}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Parent</p>
                                <p className="text-md font-semibold">{transaction?.user?.firstname} {transaction?.user?.lastname}</p>
                                <p className="text-xs text-gray-400">{transaction?.user?.email}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Total requested amount</p>
                                <p className="text-md font-semibold">{formatter.format(transaction?.total_wards_fee)}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="flex gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Credit Decision</p>
                                <button style={transaction?.credit_decision === "approved" ? { background: 'rgb(139, 231, 139)' } : transaction?.credit_decision === "declined" ? { background: 'rgb(227, 79, 79)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm font-bold">
                                    {transaction?.credit_decision === "approved" ? "Approved" : transaction?.credit_decision === "declined" ? "Declined" : "In Review"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="flex gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Flexpay Status</p>
                                <button
                                    className={`py-1 px-2 capitalize rounded-2xl text-sm font-bold 
                                        ${transaction.status === "full" ? "bg-green-200 text-green-800" : 
                                        transaction.status === "reject" ? "bg-red-200 text-red-800" : 
                                        transaction.status === "active" ? "bg-blue-200 text-blue-800" : 
                                        transaction.status === "cancelled" ? "bg-orange-200 text-orange-800" : 
                                        transaction.status === "initiated" ? "bg-cyan-200 text-cyan-800" : 
                                        "bg-yellow-200 text-yellow-800"}`}
                                    >
                                    {transaction?.status === "full" ? "Approved" : 
                                    transaction?.status === "reject" ? "Rejected" : 
                                    transaction?.status === "initiated" ? "Initiated" : 
                                    transaction?.status === "cancelled" ? "Cancelled" : 
                                    transaction?.status === "active" ? "Active" : 
                                    "Pending"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Duration</p>
                                <p className="text-md font-semibold">{transaction?.length_of_payback} Months</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Term / Session</p>
                                <p className="text-md font-semibold">{displaySchoolTerm(transaction?.term)} - {transaction?.session}</p>
                            </div>
                        </div>
                    </div>
                    <p className="block font-semibold w-full px-4 py-2 my-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Students
                    </p>
                    <ul className="text-sm font-medium text-gray-900 bg-white border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {transaction?.choose_wards?.map((ward) => (
                            <li className="block w-full flex justify-between py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                                <span>{ward.firstname} {ward.lastname}</span> - <span>JSS 2</span>
                            </li>
                        ))}
                    </ul>
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Date</p>
                                <p className="text-md font-semibold">{(new Date(transaction.createdAt)).toLocaleDateString('en-NG')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white border dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3 col-span-1 lg:col-span-2">
                <div className="">
                    <p className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-800 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Payment History
                    </p>
                </div>
                <DataTable
                    data={transaction?.repayments}
                    columns={columns}
                    getRowId={(row) => row?._id}
                    pagination
                    customStyles={customStyles}
                    progressPending={loading}
                    progressComponent={
                        <ImSpinner className="animate-spin text-4xl text-gray-400" />
                    }
                />
            </div>
        </div>
    )}
</div>

  )
}

export default TransactionDetails