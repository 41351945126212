import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { formatter, getPriceForTerm } from '../utils/currencyUtils';
import { ImSpinner } from "react-icons/im";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import classroom from '../data/classroom.png';
import { Header, AddClassModal } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Classes = () => {
  useKYCStatusRedirect("/classes");
  const { school } = useStateContext();
  const [classes, setClasses] = useState();
  const [class_name, setClassname] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const schoolID = school?._id;
  const schoolCode = school?.code;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(accessCode);
  //console.log(schoolCode);

  useEffect(() => {
    const fetchClasses = async () => {
      if (schoolCode) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`classes?code=${schoolCode}`);
          //console.log(data.classes);
          setClasses(data.classes);
          setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchClasses();
  }, [schoolCode, refresh]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!class_name || !price1 || !price2 || !price3 || !level_of_education) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post("/classes",
        { 
          schoolId:schoolID,
          class_name, 
          class_price: {
            1: price1,
            2: price2,
            3: price3
          },
          level_of_education,
        });
      //console.log(data);
      setRefresh(!refresh);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setClassname("");
        setPrice1("");
        setPrice2("");
        setPrice3("");
        setLevelOfEducation("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const deleteHandler = async (classId) => {
    //console.log(classId);
    try {
      await userRequest.delete(`classes/${schoolID}/${classId}`);
      //console.log(res.data);
      //setClasses(res.data.classes)
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  
  const columns = [
    {
      cell: (row) => <img className="rounded-xl h-18 md:ml-3" src={classroom} style={{ width: '4rem' }} alt="Course"/>,
      ignoreRowClick: true,
      button: true,
    },
    {
      field: "name",
      name: "Class Name",
      selector: row => <span className="font-bold">{row.class_name}</span>
    },
    {
      field: "price",
      name: "Amount",
      selector: row => formatter.format(getPriceForTerm(row.class_price, school.term))
    },
    /*{
      cell: (row) => <button style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">Active</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },*/
    {
      cell: (row) => <Link to={`/students/${row._id}`}><button className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2.5 hover:drop-shadow-xl">Students</button></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <Link to={`/addons/${row._id}/${row.class_name}`}><button className="focus:outline-none text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 hover:drop-shadow-xl">Fees</button></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <Link to={`/update-class/${row._id}`} style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiEdit /></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button onClick={() => deleteHandler(row._id)} style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiTrash2 /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
        Add New Class
      </button>

      <Header category="Set Up" title="Classes" />
      <DataTable
        data={classes}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />

      {isModalOpen && 
        <AddClassModal 
        closeModal={toggleModal} 
        class_name={class_name}
        setClassname={setClassname}
        //class_price={class_price}
        //setClassPrice={setClassPrice}
        price1={price1} 
        setPrice1={setPrice1} 
        price2={price2} 
        setPrice2={setPrice2} 
        price3={price3} 
        setPrice3={setPrice3}
        level_of_education={level_of_education}
        setLevelOfEducation={setLevelOfEducation}
        error={error}
        success={success}
        loading={loading} 
        submitHandler={submitHandler}
        />
      }
    </div>
  );
};
export default Classes;
