import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { formatter, getPriceForTerm } from '../utils/currencyUtils';
import { ImSpinner } from "react-icons/im";
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Header, PaymentSummary } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const ClassPayments = () => {
  useKYCStatusRedirect("/class-payments");
  const { school } = useStateContext();
  const [classes, setClasses] = useState();
  const [paymentSummary, setPaymentSummary] = useState();
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [loading, setLoading] = useState(false);
  //console.log(accessCode);
  //console.log(schoolCode);

  useEffect(() => {
    if (schoolCode && schoolId) {
      fetchData();
    }
  }, [schoolCode, schoolId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [classesResponse, paymentSummaryResponse] = await Promise.all([
        userRequest.get(`classes?code=${schoolCode}`),
        userRequest.get(`schools/${schoolId}/payment-summary?term=${school.term}&session=${school.session}`),
      ]);

      setClasses(classesResponse.data.classes);
      setPaymentSummary(paymentSummaryResponse.data);
      console.log(paymentSummaryResponse.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Class Name",
      selector: row => row.class_name
    },
    {
      name: "Level of Education",
      selector: row => <span className="capitalize">{row.level_of_education} </span>
    },
    {
      name: "School Fees",
      selector: row => <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{formatter.format(getPriceForTerm(row.class_price, school.term))}</button>
    },
    {
      name: "Options",
      selector: (row) => <Link to={`/class-transaction/${row?._id}/${row?.class_name}`}><button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">View Details <MdKeyboardArrowRight /></button></Link>,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg min-h-screen">
      <Header category="Payments" title="Payment Per Class" />
      <PaymentSummary paymentSummary={paymentSummary} />
      <DataTable
        data={classes}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />
    </div>
  );
};
export default ClassPayments;
