import React from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { formatter } from '../utils/requestMethods';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
};

const TransactionTable = ({ transactions, loading }) => {
  
    const columns = [
      {
        name: "Who Paid?",
        selector: row => row.metadata.parent_name
      },
      {
        name: "Student Name",
        selector: row => row.child?.firstname + " " + row.child?.lastname
      },
      {
        name: "Student Class",
        selector: row => row.metadata?.class?.class_name
      },
      {
        name: "Amount Paid",
        selector: row => formatter.format(row.amount)
      },
      {
        name: "Total Fees",
        selector: row => formatter.format(row.total_amount)
      },
      {
        name: "Total Paid",
        selector: row => formatter.format(row.amount_after)
      },
      {
        name: "Balance",
        selector: row => formatter.format(Number(row.total_amount) - Number(row.amount_after))
      },
      {
        name: "Paid At",
        selector: row => (new Date(row.updatedAt)).toLocaleString('en-NG', {hour12: true}),
        sortable: true
      },
      {
        cell: (row) => <Link to={`/transaction-details/${row._id}`}><button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button></Link>,
        ignoreRowClick: true,
        button: true,
      },
    ];
  
    return (
      <div className="rounded-2xl">
          <DataTable
            data={transactions}
            columns={columns}
            getRowId={(row) => row._id}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={
              <ImSpinner className="animate-spin text-4xl text-gray-400" />
            }
          />
      </div>
    );
  };

export default TransactionTable