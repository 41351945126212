import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatter, userRequest } from '../utils/requestMethods';
import { displaySchoolTerm } from '../utils/schoolYearUtils';
import { customFormatter } from '../utils/currencyUtils';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FiBarChart } from 'react-icons/fi'
//import { Button, PaymentSummary } from '../components';
//import { recentTransactions } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { StatementModal } from '../components';

const Home = () => {
  useKYCStatusRedirect("/home");
  const { user, school, currentColor } = useStateContext();
  const schoolId = school?._id;
  const [balance, setBalance] = useState({});
  const [payout, setPayout] = useState({});
  const [paymentSummary, setPaymentSummary] = useState();
  //const [account, setAccount] = useState({});
  console.log(school);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const {data} = await userRequest.get(`schools/${schoolId}/account-balance`);
        //console.log(data.data);
        setBalance(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBalance();
  }, [schoolId]);

  useEffect(() => {
    const fetchPayout = async () => {
      try {
        const {data} = await userRequest.get(`/transactions/school/${schoolId}/payout-summary`);
        //console.log(data);
        setPayout(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPayout();
  }, [schoolId]);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const {data} = await userRequest.get(`schools/${schoolId}/payment-summary?term=${school.term}&session=${school.session}`);
        //console.log(data);
        setPaymentSummary(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
  }, [schoolId, school?.term, school?.session]);
  
  return (
    <div className="mt-10">
      <h1 className="text-2xl font-extrabold tracking-tight text-slate-900 px-8 pt-8 dark:text-gray-200">Welcome, {user?.firstname}! <small className="float-right">{displaySchoolTerm(school?.term)} - {school?.session}</small></h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 md:m-2 mt-10 p-2 md:p-5">
        <div className="bg-white shadow dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-2xl p-8 pt-9 m-3">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">NGN Balance</p>
              <p className="text-2xl">{customFormatter(balance?.availableBalance)}</p>
            </div>
            <button type="button" style={{ backgroundColor: currentColor, fontFamily: "Nunito" }} className="text-2xl opacity-90 text-white hover:drop-shadow-xl rounded-full py-4 px-6">
              ₦
            </button>
          </div>
          <div className="mt-6">
            <Link to="/withdraw">
              <p className="text-sm text-gray-400 mt-1">Withdraw Balance <MdKeyboardArrowRight style={{ display: 'inline' }} /></p>
            </Link>
          </div>
        </div>
        <div className="bg-white shadow h-44 dark:text-gray-200 dark:bg-secondary-dark-bg p-8 pt-9 m-3 rounded-2xl">
          <button type="button" style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-2xl opacity-90 rounded-full p-4 hover:drop-shadow-xl">
            <FiBarChart />
          </button>
          <p className="mt-3">
            <span className="text-lg font-semibold">{customFormatter(payout?.totalPayout)}</span>
          </p>
          <Link to="/withdraw">
            <p className="text-sm text-gray-400 mt-1">Balance Payouts <MdKeyboardArrowRight style={{ display: 'inline' }} /></p>
          </Link>
        </div>
        <div className="bg-white shadow h-44 dark:text-gray-200 dark:bg-secondary-dark-bg p-8 pt-9 m-3 rounded-2xl">
          <button type="button" style={{ color: 'white' }} className="text-2xl opacity-90 rounded-full bg-[#24292F] hover:bg-[#24292F]/90 p-4 hover:drop-shadow-xl">
            <FiBarChart />
          </button>
          <p className="mt-3">
            <span className="text-lg font-semibold">{customFormatter(0)}</span>
          </p>
          <Link to="/financing/cashflow">
            <p className="text-sm text-gray-400 mt-1">Cashflow <MdKeyboardArrowRight style={{ display: 'inline' }} /></p>
          </Link>
        </div>
      </div>


      <div className="grid my-2 m-2 md:m-2 md:px-3 items-center">
        <div className="bg-[#F9F5F0] shadow dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-8 pt-9 m-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 md:gap-3 lg:gap-x-2 text-center items-center">
            <Link to="/withdraw">
              <button type="button" className="text-white bg-[#FB9678] hover:bg-[#FB9678]/90 focus:ring-4 focus:outline-none focus:ring-[#FB9678]/50 font-medium rounded-lg text-sm px-8 sm:px-16 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-[#3b5998]/55 m-2 w-full">
                <i className="fad fa-money-check w-4 mr-2"></i>
                Withdraw
              </button>
            </Link>
            <Link to="/expenses">
              <button type="button" className="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-8 sm:px-16 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-[#1da1f2]/55 m-2 w-full">
                <i className="fad fa-exchange-alt w-4 mr-2"></i>
                Transfer
              </button>
            </Link>
            <button type="button" onClick={toggleModal} className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-8 sm:px-16 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 m-2 w-full">
              <i className="fal fa-receipt w-4 mr-2"></i>
              Statement
            </button>
            <Link to="/home">
              <button type="button" className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-8 sm:px-16 py-2.5 text-center inline-flex items-center justify-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 m-2 w-full">
                <i className="fad fa-file-alt w-4 mr-2"></i>
                Pay Bills
              </button>
            </Link>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-2 m-2 p-2 md:p-5">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-lg font-semibold">Account Details</p>
          </div>
          <hr />
          <div className="mt-4">
            <div className="flex justify-between mt-4 w-full">
              <div className="flex gap-4 mb-2">
                <button type="button" style={{ background: '#FB9678' }} className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3">
                  <i className="fal fa-wallet"></i>
                </button>
                <div>
                  <p className="text-md font-semibold">9537111528</p>
                  <p className="text-xs text-gray-400">Providus Bank</p>
                  <p className="text-xs text-gray-400">{school?.school_name}</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4">
              <p className="mt-2 text-sm text-gray-500">
                This <b>account</b> is an NDIC issured deposit account, that holds your main balance from all fee payments on Acceede.
              </p>
              <p className="mt-2 text-sm text-gray-500">
                You can transfer from any <b>bank account</b> to this <b>account</b> instantly with the account number above. You can also withdraw from this account using the withdraw feature.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3 md:col-span-2">
          <div className="flex justify-between">
            <p className="text-lg font-semibold">Transaction Summary</p>
          </div>
          <hr />
          <div className="gap-4 border-color mt-6">
            <div className="max-w-screen-xl w-full">
              <div className="grid mb-8 rounded-lg shadow-sm md:mb-12 md:grid-cols-2 bg-white">
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white bg-blue-700 mr-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <i className="fas fa-chart-line"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <div>{formatter.format(paymentSummary?.totalAmount || 0)}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">Expected Income</div>
                    </div>
                  </figcaption>
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <i className="fas fa-chart-line"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <div>{formatter.format(paymentSummary?.totalAmountPaid || 0)}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 ">Total Paid</div>
                    </div>
                  </figcaption>
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <i className="fas fa-chart-line-down"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <div>{formatter.format(paymentSummary?.totalOutstandingAmount || 0)}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 ">Current Debt</div>
                    </div>
                  </figcaption>  
                </figure>
                <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                  <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <i className="fas fa-chart-line-down"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                      <div>{formatter.format(paymentSummary?.discountAmount || 0)}</div>
                      <div className="text-sm text-gray-500 dark:text-gray-400 ">Discount</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && 
        <StatementModal
        closeModal={toggleModal}
        school={school}
      />}
    </div>
  );
};

export default Home;
