import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userRequest } from '../utils/requestMethods';
import { BackButton, Header, PaymentsTable, PaymentSummary } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { generateSchoolSessions } from '../utils/schoolYearUtils';

const TransactionPerClass = ({history}) => {
  const schoolSessions = generateSchoolSessions(2022, 20);
  const { school, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState();
  const [paymentSummary, setPaymentSummary] = useState();
  //const [startDate, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(new Date());
  const [term, setTerm] = useState("");
  const [session, setSession] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const schoolId = school?._id;
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  const nameOfClass = location.pathname.split('/')[3];
  //console.log(accessCode);
  //console.log(userId);

  useEffect(() => {
    if (classId && schoolId) {
      fetchData();
    }
  }, [classId, schoolId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [classesPaymentResponse, paymentSummaryResponse] = await Promise.all([
        userRequest.get(`/payments/school/${schoolId}?class_id=${classId}&term=${school.term}&session=${school.session}&limit=200&sort_by=date&order=des`),
        userRequest.get(`schools/${schoolId}/payment-summary?class_id=${classId}&term=${school.term}&session=${school.session}`),
      ]);

      setTransactions(classesPaymentResponse.data.payments);
      setPaymentSummary(paymentSummaryResponse.data);
      console.log(paymentSummaryResponse.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    //const start = new Date(startDate).toISOString().slice(0, 10);
    //const end = new Date(endDate).toISOString().slice(0, 10);

    try {
      //const { data } = await userRequest.get(`/payments/school/${schoolId}?class_id=${classId}&term=${term}&session=${session}&limit=200&sort_by=date&order=des`);
      //console.log(data);
      //setTransactions(data.payments);
      const [classesPaymentResponse, paymentSummaryResponse] = await Promise.all([
        userRequest.get(`/payments/school/${schoolId}?class_id=${classId}&term=${term}&session=${session}&limit=200&sort_by=date&order=des`),
        userRequest.get(`schools/${schoolId}/payment-summary?class_id=${classId}&term=${term}&session=${session}`),
      ]);

      setTransactions(classesPaymentResponse.data.payments);
      setPaymentSummary(paymentSummaryResponse.data);
      console.log(paymentSummaryResponse.data);
    } catch (error) {
        console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen">
      <div className="flex flex-wrap justify-between mb-4">
        <BackButton history={history} />
        <button type="button" onClick={() => setClicked(!clicked)} style={{ backgroundColor: currentColor }} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
            Filters
        </button>
      </div>
        <Header category="Payments" title={nameOfClass} />
        {clicked ? (
          <div className="max-w-xl w-full ml-auto">
            <div className="md:grid gap-6 mb-6 md:grid-cols-3 justify-end">
              <div>
                  <label htmlFor="startDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Term</label>
                  <select id="term" onChange={(e) => setTerm(e.target.value)} defaultValue={term} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
              </div>
              <div>
                  <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Session</label>
                  <select id="session" onChange={(e) => setSession(e.target.value)} defaultValue={session} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" required>
                    <option value="">Select</option>
                    {schoolSessions.map(session => (
                      <option key={session.value} value={session.value}>
                        {session.label}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="mt-6">
                  <button type="submit" disabled={loading} onClick={submitHandler} className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Search</button>
              </div>
            </div>
          </div>
        ) : null }
        <PaymentSummary paymentSummary={paymentSummary} />
        <PaymentsTable transactions={transactions} loading={loading} />
    </div>
  );
};
export default TransactionPerClass;
