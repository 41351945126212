import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { userRequest, formatter } from '../utils/requestMethods';
import { BackButton, Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";
import { displaySchoolTerm } from '../utils/schoolYearUtils';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
  };

const PaymentDetails = ({ history }) => {
    const { school } = useStateContext();
    const schoolId = school?._id
    const location = useLocation();
    const paymentId = location.pathname.split('/')[3];
    //const studentId = location.pathname.split('/')[4];
    console.log(paymentId);
    const [payment, setPayment] = useState();
    const [transactions, setTransactions] = useState();
    const [loading, setLoading] = useState(false);

    /*useEffect(() => {
        const fetchClasses = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`/payments/child/${studentId}`);
            console.log(data);
            //setTransaction(data);
            //setTransactions(data.repayments)
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchClasses();
    }, [studentId]);*/

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setLoading(true);
                const { data } = await userRequest.get(`/transactions/payment/${schoolId}/${paymentId}?limit=100&sort_by=date&order=des`);
                console.log(data);
                setTransactions(data.transactions);
                setPayment(data.payment);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTransactions();
    }, [paymentId, schoolId]);

    const columns = [
        {
          name: "Total Fee Amount",
          selector: row => <b>{formatter.format(row?.total_amount)}</b>
        },
        {
          name: "Amount Paid",
          selector: row => <b>{formatter.format(row?.amount)}</b>
        },
        {
          name: "Balance",
          selector: row => formatter.format(Number(row.total_amount) - Number(row.amount_after))
        },
        {
          name: "Status",
          selector: (row) => <button style={row?.status === "successful" ? { background: 'rgb(139, 231, 139)' } : row?.status === "failed" ? { background: 'rgb(227, 79, 79)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row?.status=== "successful" ? ("Successful") : row?.status === "failed" ? ("Failed") : ("Pending")}</button>
        },
        {
          name: "Date",
          selector: row => (new Date(row.updatedAt)).toLocaleString('en-NG', {hour12: true}),
          sortable: true
        },
      ];
    
  return (
    <div className="m-4 md:m-10 mt-24 p-4 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        <div className="flex flex-wrap justify-between mb-4">
            <BackButton history={history} />
        </div>
        <Header category="Payment" title={payment?.child ? `${payment.child.firstname} ${payment.child.lastname}` : "Student Information"} />

    {loading ? (
        <div className="py-4 flex justify-center items-center h-full">
            <div className="max-w-xl mx-auto mt-3 divide-y">
                <div className="grid grid-cols-1 gap-6">
                    <p className="block text-center">
                        <ImSpinner className="animate-spin text-4xl text-gray-400" />
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mb-2 m-2 mt-10">
            <div className="bg-white border dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
                <div>
                    <p className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-800 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Student Payment Details
                    </p>
                </div>
                <div className="mt-4">
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Student Name</p>
                                <p className="text-md font-semibold">{payment?.child?.firstname} {payment?.child?.lastname}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Parent</p>
                                <p className="text-md font-semibold">{payment?.user?.firstname} {payment?.user?.lastname}</p>
                                <p className="text-xs text-gray-400">{payment?.user?.email}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Student Class</p>
                                <p className="text-md font-semibold">{payment?.class?.class_name}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">School Fees</p>
                                <p className="text-md font-semibold">{payment?.class_price}</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">                    
                            <div>
                                <p className="text-xs text-gray-400">Term / Session</p>
                                <p className="text-md font-semibold">{displaySchoolTerm(payment?.term)} - {payment?.session}</p>
                            </div>
                        </div>
                    </div>
                    <p className="block font-semibold w-full px-4 py-2 my-2 rounded-lg text-white bg-indigo-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Other Fees
                    </p>
                    <ul className="text-sm font-medium text-gray-900 bg-white border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {payment?.add_ons?.map((addon) => (
                            <li className="block w-full flex justify-between py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                                <span>{addon.add_on_name}</span> - <span>{addon.add_on_price}</span>
                            </li>
                        ))}
                        {payment?.transport_add_on &&
                            <li className="block w-full flex justify-between py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                            <span>Bus: {payment?.transport_add_on?.add_on_name}</span> - <span>{payment?.transport_add_on?.add_on_price}</span>
                        </li>}
                    </ul>
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">                    
                            <div>
                                <p className="text-xs text-gray-400">Discount</p>
                                <p className="text-md font-semibold">{formatter.format(payment?.discount?.amount || 0)} - ({payment?.discount?.percentage || 0}%)</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex justify-between mt-4 w-full">
                        <div className="gap-4 mb-2">
                            <div>
                                <p className="text-xs text-gray-400">Total Fee Amount</p>
                                <p className="text-md font-semibold">{formatter.format(Number(payment?.total_amount) - Number(payment?.discount?.amount || 0))}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white border dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3 col-span-1 lg:col-span-2">
                <div className="">
                    <p className="block font-semibold w-full px-4 py-2 rounded-lg text-white bg-indigo-800 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600">
                        Payment History
                    </p>
                </div>
                <DataTable
                    data={transactions}
                    columns={columns}
                    getRowId={(row) => row?._id}
                    pagination
                    customStyles={customStyles}
                    progressPending={loading}
                    progressComponent={
                        <ImSpinner className="animate-spin text-4xl text-gray-400" />
                    }
                />
            </div>
        </div>
    )}
</div>

  )
}

export default PaymentDetails