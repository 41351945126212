import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { userRequest, formatter } from '../utils/requestMethods';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { generateSchoolSessions } from '../utils/schoolYearUtils';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const AllTransactions = () => {
  useKYCStatusRedirect("/flexpay");
  const schoolSessions = generateSchoolSessions(2022, 20);
  const { user, school, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState();
  const [term, setTerm] = useState("");
  const [session, setSession] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const userId = user?._id;
  const schoolId = school?._id;
  //console.log(accessCode);
  //console.log(userId);
  /*console.log(schoolId);
  const start = new Date(startDate)?.toISOString().slice(0, 10);
  const end = new Date(endDate)?.toISOString().slice(0, 10);
  console.log(start + " " + end);*/


  useEffect(() => {
    const fetchTransactions = async () => {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`/loans/school/${schoolId}?limit=200`);
          //console.log(data);
          setTransactions(data.loansAndRecova);
          setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    fetchTransactions();
  }, [userId, schoolId]);

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await userRequest.get(`/loans/school/${schoolId}?limit=1000&sort_by=date&order=des&term=${term}&session=${session}`);
      //console.log(data);
      setTransactions(data.transactions);
    } catch (error) {
        console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Parent Name",
      selector: row => row.user?.firstname +" "+ row.user?.lastname
    },
    {
      name: "Total Amount Requested",
      selector: row => <b>{formatter.format(row.total_wards_fee)}</b>
    },
    {
      name: "Credit Decision",
      selector: (row) => <button style={row.credit_decision === "approved" ? { background: 'rgb(139, 231, 139)' } : row.credit_decision === "declined" ? { background: 'rgb(227, 79, 79)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row.credit_decision=== "approved" ? ("Qualified") : row.credit_decision === "declined" ? ("Declined") : ("In Review")}</button>
    },
    {
      name: "Status",
      selector: (row) => (
        <button
          className={`py-1 px-2 capitalize rounded-2xl text-md font-bold 
            ${row.status === "full" ? "bg-green-200 text-green-800" : 
              row.status === "reject" ? "bg-red-200 text-red-800" : 
              row.status === "active" ? "bg-blue-200 text-blue-800" : 
              row.status === "cancelled" ? "bg-orange-200 text-orange-800" : 
              row.status === "initiated" ? "bg-cyan-200 text-cyan-800" : 
              "bg-yellow-200 text-yellow-800"}`}
        >
          {row?.status === "full" ? "Approved" : 
           row?.status === "reject" ? "Rejected" : 
           row?.status === "initiated" ? "Initiated" : 
           row?.status === "cancelled" ? "Cancelled" : 
           row?.status === "active" ? "Active" : 
           "Pending"}
        </button>
      )},
    {
      name: "Date",
      selector: row => (new Date(row.createdAt)).toLocaleDateString('en-NG'),
      sortable: true
    },
    {
      cell: (row) => <Link to={`/flexpay/flexpay-details/${row.loanReference}`}><button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button></Link>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
        <button type="button" onClick={() => setClicked(!clicked)} style={{ backgroundColor: currentColor }} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
            Filters
        </button>
        <Header category="FlexPay" title="Request" />
        {clicked ? (
          <div className="max-w-xl w-full ml-auto">
            <div className="md:grid gap-6 mb-6 md:grid-cols-3 justify-end">
              <div>
                  <label htmlFor="startDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Term</label>
                  <select id="term" onChange={(e) => setTerm(e.target.value)} defaultValue={term} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
              </div>
              <div>
                  <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Session</label>
                  <select id="session" onChange={(e) => setSession(e.target.value)} defaultValue={session} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" required>
                    <option value="">Select</option>
                    {schoolSessions.map(session => (
                      <option key={session.value} value={session.value}>
                        {session.label}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="mt-6">
                  <button type="submit" disabled={loading} onClick={submitHandler} className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Search</button>
              </div>
            </div>
          </div>
        ) : null }
        {success &&
          <div className="alert alert-success d-flex align-items-center" role="alert">
            <i className="fal fa-check pr-10"></i>
            <div>
              Success!
            </div>
          </div>
        }
        {error &&
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10"></i>
            <div>
              Error Occurred: {error}
            </div>
          </div>
        }
        <DataTable
          data={transactions}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />
    </div>
  );
};
export default AllTransactions;
