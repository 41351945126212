import React, { useState, useEffect } from 'react';
import { userRequest, formatter } from '../utils/requestMethods';
import DataTable from 'react-data-table-component';
import { ImSpinner } from "react-icons/im";
import { FiArrowRight } from 'react-icons/fi';
import { customFormatter } from '../utils/currencyUtils';
import { Header, WithdrawModal } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Withdraw = () => {
  useKYCStatusRedirect("/withdraw");
  const { user, school } = useStateContext();
  const userId = user?._id;
  const schoolID = school?._id;
  const [balance, setBalance] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [recipientCode, setRecipientCode] = useState("");
  //const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  //console.log(accessCode);
  //console.log(recipientCode);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const {data} = await userRequest.get(`schools/${schoolID}/account-balance`);
        //console.log(data.data);
        setBalance(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBalance();
  }, [schoolID, refresh]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`transactions/${userId}/${schoolID}?type=withdrawal&sort_by=date&order=des`);
        //console.log(data);
        setTransactions(data.transactions);
        setLoading(false);
      } catch (error) {
          console.log(error);
      }
    };
    fetchTransactions();
  }, [userId, schoolID, refresh]);

  /*fetch banks detais
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const res = await userRequest.get(`banks/${schoolID}`);
        //console.log(res.data);
        setBankDetails(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDetails();
  }, [schoolID]);*/

  useEffect(() => {
    //setPageLoading(true);
    // Update pay out account detail if available
    if (school) {
        setBankDetails(school.counter_party);
        //console.log("ran!")
    }
    //setPageLoading(false);
}, [school]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!amount || !otp) {
      setError("Please fill all the feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    
    try {
      await userRequest.post(`transactions/school/${schoolID}/withdraw`,
        { 
          amount,
          //recipient_code: recipientCode, 
          otp,
          reason: "School Payout",
        });
      //console.log(data);
      setRefresh(!refresh);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setAmount("");
        setRecipientCode("");
        setOtp("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Amount",
      selector: row => formatter.format(row.amount)
    },
    {
      name: "Transaction Fee",
      selector: row => formatter.format(row.transaction_fee || 0)
    },
    {
      name: "Final Amount Paid",
      selector: row => formatter.format(row.final_amount)
    },
    {
      name: "Status",
      selector: row => <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row.status}</button>
    },
    {
      name: "Payment Date",
      selector: row => (new Date(row.created_at)).toLocaleDateString('en-NG'),
      sortable: true
    },
    {
      cell: (row) => <button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl min-h-screen">
      <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
        Withdraw
      </button>
      <Header category="Pay Outs" title="Make Withdrawal" />
      <div class="mt-2 max-w-full divide-y">
        <div class="grid mb-8 rounded-lg shadow-sm md:mb-12 md:grid-cols-3 bg-white">
            <figure class="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption class="flex items-center justify-center ">
                  <button type="button" class="text-white bg-blue-700 mr-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <i class="fas fa-chart-line"></i>
                  </button>
                  <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                    <div>{customFormatter(balance?.availableBalance)}</div>
                    <div class="text-sm text-gray-500 dark:text-gray-400 ">Main Balance</div>
                  </div>
                </figcaption>    
            </figure>
            <figure class="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption class="flex items-center justify-center ">
                  <button type="button" class="text-white mr-2 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <i class="fas fa-chart-line-down"></i>
                  </button>
                  <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                    <div>{customFormatter(balance?.total_payout)}</div>
                    <div class="text-sm text-gray-500 dark:text-gray-400 ">Total Payout</div>
                  </div>
                </figcaption>    
            </figure>
            <figure class="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption class="flex items-center justify-center ">
                  <button type="button" class="text-white mr-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <i class="far fa-wallet"></i>
                  </button>            
                  <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                    <div class="text-sm text-gray-500 dark:text-gray-400">Acceede Training Heights</div>
                    <div>2937483721</div>
                    <div class="text-sm text-gray-500 dark:text-gray-400">Wema Bank</div>
                  </div>
                </figcaption>    
            </figure>
        </div>
      </div>

      <div class="py-4 overflow-scroll md:overflow-hidden">
        <p class="text-lg text-gray-600 dark:text-gray-200">
          <b>Recent Transactions</b>
        </p>
        <hr />
        <DataTable
          data={transactions}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />

        {isModalOpen && 
          <WithdrawModal 
            closeModal={toggleModal} 
            amount={amount}
            setAmount={setAmount}
            recipientCode={recipientCode}
            setRecipientCode={setRecipientCode}
            otp={otp}
            setOtp={setOtp}
            bankDetails={bankDetails}
            error={error}
            success={success}
            loading={loading} 
            submitHandler={submitHandler}
            school={school}
          />
        }
      </div>
    </div>
  )
};

export default Withdraw;
